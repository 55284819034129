<template>
    <footer >
        <section>
            <div>
                <a href="/">
                    <img id="logoFooter" src="../assets/img/sole_tracking.png">
                </a>
            </div>
            <div>
                <h5 class="hideMobile">SOLE TRACKING®</h5>
                <ul>
                    <li>
                        <a href="mailto:contacto@soletracking.com" ><img src="../assets/img/icono-mail-footer.svg"> contacto@soletracking.com</a>
                    </li>
                    <li>
                        <a href="mailto:ventas@soletracking.com"><img src="../assets/img/icono-mail-footer.svg"> ventas@soletracking.com</a>
                    </li>
                    <li>
                        <a href="https://wa.me/5213310149291?text=Buen%20día%20necesito%20más%20información%20sobre%20Sole%20tracking" target="_blank"><img src="../assets/img/whatsapp-footer.svg"> +521 33 1014 9291</a>
                    </li>
                </ul>
                <ul id="socialMediaIcons">
                    <li>
                        <a href="https://www.facebook.com/SoLeTracking" target="_blank">
                            <img src="../assets/img/Facebook.svg">
                        </a>
                    </li>
                    <li>
                        <a href="https://wa.me/5213331015825?text=Buen%20día%20necesito%20más%20información%20sobre%20Sole%20tracking" target="_blank">
                            <img src="../assets/img/Whatsapp.svg">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@soletracking" target="_blank">
                            <img src="../assets/img/Youtube.svg">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/soletracking/" target="_blank">
                            <img src="../assets/img/Instagram.svg">
                        </a>
                    </li>
                </ul>
            </div>
        </section>
        <section>
            <a href="/privacy">
                <h5>AVISO DE PRIVACIDAD</h5>
            </a>
        </section>
    </footer>
</template>
<script>
export default {

}
</script>
<style lang="less">
footer{
    background-color: #E8E8E8;

    section{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        &:first-child{
            padding: 5em 0;
        }

        &:last-child{
            background-color: #CB007B;
            color: white;
            padding: 2.5em;

            h5{
                margin: 0;
                color: white;
            }

        }

        div{
            min-width: 300px;
        }

        div:first-child{
            padding-right: 4em;
            border-right: 0.5em solid #D9D9D9;
        }

        div:last-child{
            padding-left: 4em;

            h5{
                text-align: left;
                font-size: 15pt;
                font-family: "Montserrat-Bold";
                margin-bottom: 1.5em;
            }


            ul{
                list-style: none;
                padding: 0;
                padding-left: 0.5em;
                font-size: 12pt;
                
                li{
                    text-align: left;
                    margin-bottom: 0.7em;

                    img{
                        margin-right: 1em;  
                    }
                }
            }

            #socialMediaIcons{
                display: flex;
                margin-top: 2em;
                justify-content: center;
            }
        }

        #logoFooter{
            width: 100%;
            max-width: 300px;
            padding: 10%;
        }
    }
}

@media (max-width: 1024px){
    .hideMobile{
        display: none;
    }

    footer{
        section{
            div:first-child{
                margin-bottom: 4em;
            }

            div:first-child,
            div:last-child{
                padding:0;
                border:none;
            }

            #logoFooter{
                padding:0;
            }
        }
    }
}
</style>